export default defineNuxtRouteMiddleware((to, from) => {
  if (to.params.cityUri) {
    const isValid =
      to.params.cityUri &&
      Object.values(provincialCities).includes(to.params.cityUri as string);

    if (!isValid) {
      return navigateTo("/not-found");
    }
  }
});
